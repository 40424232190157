import React, { useState } from 'react';

import withLayout from '../layout';
import { Input } from '../components/Form/Input';
import { apiClearDomainCache } from '../util/api';

const CacheClearPage = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(undefined);
  const [status, setStatus] = useState(-1);

  const onChange = event => {
    const val = event.target.value;
    setValue(val);
  };

  const hasError = () => {
    return typeof value === 'string' && value.length < 1;
  };

  const onSubmit = async event => {
    event.preventDefault();

    if (hasError()) {
      return;
    }

    setLoading(true);
    setStatus(-1);
    const statusCode = await apiClearDomainCache(value);
    setStatus(statusCode);
    setLoading(false);
  };

  return (
    <div
      className="container"
      style={{
        margin: `0 auto`,
      }}
    >
      <p>Geben Sie hier eine Domain ein, um den age-xml Cache dieser Domain zu leeren.</p>
      {status > 0 && (
        <div
          className={`toast ${status === 200 ? 'toast-success' : 'toast-error'}`}
          style={{ marginBottom: 20 }}
        >
          {status === 200
            ? 'Der Cache wurde erfolgreich geleert.'
            : status === 400
            ? 'Sie müssen eine gültige Domain angeben.'
            : 'Es ist ein unbekannter Fehler aufgetreten. Versuchen Sie es später erneut.'}
        </div>
      )}
      <form onSubmit={onSubmit}>
        <Input
          type="text"
          name="domain"
          handleChange={onChange}
          value={value}
          label="Domain"
          required={true}
          hasError={hasError()}
          hint="Sie müssen eine Domain angeben!"
        />
        <button className={`btn${loading ? ' loading' : ''}`} type="submit" disabled={loading}>
          Absenden
        </button>
      </form>
    </div>
  );
};

const customProps = {
  localeKey: 'clear-cache', // same as file name in src/i18n/translations/your-lang/index.js
  withAuth: false,
  title: 'Age-Xml Cache Clearer',
};

export default withLayout(customProps)(CacheClearPage);
